/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import {
  EmployerDetails,
  EmployerDetailsProps,
  ParamType,
} from "../../../interface/globalInterface";
import BasicInfo from "./BasicInfo";
import IncomeVerification from "./IncomeVerification";
import EmployerAddressInfo from "./EmployerAddressInfo";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { VerificationComponent } from "../VerifiedComponent";
import { useParams } from "react-router-dom";
import { HandleEdit } from "./HandleEdit";
import { EMPTY_STRING } from "../../../constants/constants";

export default function EmploymentDetails(props: {
  value: EmployerDetailsProps;
}) {
  const classes = globalStyles();
  const { configurations, updatedEmployerDetails, setUpdatedEmployerDetails, incomeDoc } =
    useContext(UpdateCustomerContext);
  const { employerDetails, setEmployerDetails, setErrorMessage, errorMessage } =
    props.value;
  console.log("employerDetails123", employerDetails, updatedEmployerDetails);
  const { pageType } = useParams<ParamType>();
  const isVerifiedCheckBoxRequired: boolean =
    pageType == "customer" && !configurations.customerVerificationOptimization;

  const checkMandatoryFields = (index?: number): boolean => {
    let isError: boolean = true;
    const isErrorPresent: any = [];
    const employerErrorMessage: any = [];
  
    // If an index is provided, validate only that index
    if (index == 0 || index) {
      const details = employerDetails[index];
      const { errorMessageDetails, errorPresent } = validateDetails(details);
      employerErrorMessage[index] = errorMessageDetails;
      console.log('Error Present in if', errorPresent, index)
      // Determine if there was any error for the specific index
      isError = errorPresent
  
    } else {
      // Validate all entries
      employerDetails.forEach((details: EmployerDetails, i: number) => {
        const { errorMessageDetails, errorPresent } = validateDetails(details);
        employerErrorMessage.push(errorMessageDetails);
        console.log('Error Present in else', errorPresent)
        isErrorPresent.push(errorPresent)
      });
      isError = isErrorPresent.some(Boolean);
    }
    console.log('Final out', isError)
    setErrorMessage(employerErrorMessage);
    return !isError;
  };
  
  // Helper function to validate a single entry
  const validateDetails = (details: EmployerDetails) => {
    let errorPresent = false;
    const errorMessageDetails = {
      basicInfo: {
        companyName: EMPTY_STRING,
        employmentYears: EMPTY_STRING,
        phoneNumber: EMPTY_STRING,
        doNotCallEnabled: false,
        isEdited: false,
      },
      incomeVerification: {
        paydayFrequency: EMPTY_STRING,
        dayPaid: EMPTY_STRING,
        takeHomePay: EMPTY_STRING,
        isEdited: false,
        payDayFreqDesc: EMPTY_STRING,
        daysPaidDesc: EMPTY_STRING,
        incomeSource: EMPTY_STRING
      },
      addressInfo: {
        addressLine1: EMPTY_STRING,
        addressLine2: EMPTY_STRING,
        zip: EMPTY_STRING,
        city: EMPTY_STRING,
        state: EMPTY_STRING,
        doNotVisit: false,
        isEdited: false,
      },
      verified: false,
      active: true,
      verifiedDate: EMPTY_STRING,
      employerDetailsId: null,
      overrideEnabled: false,
      isEditEnabled: false,
      verifiedBy: EMPTY_STRING,
      decisionEngineId: EMPTY_STRING,
      verifiedSource: EMPTY_STRING,
      verifyCode: EMPTY_STRING,
    };
  
    if (!details.basicInfo.companyName && details.active) {
      errorMessageDetails.basicInfo.companyName = "Required";
      errorPresent = true;
    }
    
    if ((!details.basicInfo.phoneNumber || details.basicInfo.phoneNumber.toString().length < 10) && details.active) {
      errorMessageDetails.basicInfo.phoneNumber = "Required";
      errorPresent = true;
    }
  
    if (!details.incomeVerification.paydayFrequency && details.active) {
      errorMessageDetails.incomeVerification.paydayFrequency = "Required";
      errorPresent = true;
    }
    
    if (details.incomeVerification.paydayFrequency !== "DAILY" &&
        details.incomeVerification.paydayFrequency !== "" &&
        !details.incomeVerification.dayPaid &&
        details.active) {
      errorMessageDetails.incomeVerification.dayPaid = "Required";
      errorPresent = true;
    }
  
    return {
      errorMessageDetails,
      errorPresent
    };
  };
  

  const setData = () => {
    if (checkMandatoryFields()) {
      console.log("Before Error message", errorMessage);
      const newEmployeeDetails = [...employerDetails];
      newEmployeeDetails.push({
        basicInfo: {
          companyName: EMPTY_STRING,
          employmentYears: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          doNotCallEnabled: false,
          isEdited: false,
        },
        incomeVerification: {
          paydayFrequency: EMPTY_STRING,
          dayPaid: EMPTY_STRING,
          takeHomePay: '0.00',
          isEdited: false,
          payDayFreqDesc: EMPTY_STRING,
          daysPaidDesc: EMPTY_STRING,
          incomeSource: EMPTY_STRING
        },
        addressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          doNotVisit: false,
          isEdited: false,
        },
        verified: false,
        active: true,
        verifiedDate: EMPTY_STRING,
        employerDetailsId: null,
        overrideEnabled: false,
        isEditEnabled: false,
        verifiedBy: EMPTY_STRING,
        decisionEngineId: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
        isCancellable: true
      });
      const updatedEmpDetails = [...updatedEmployerDetails];
      updatedEmpDetails.push({
        basicInfo: {
          companyName: EMPTY_STRING,
          employmentYears: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          doNotCallEnabled: false,
          isEdited: false,
        },
        incomeVerification: {
          paydayFrequency: EMPTY_STRING,
          dayPaid: EMPTY_STRING,
          takeHomePay: '0.00',
          isEdited: false,
          payDayFreqDesc: EMPTY_STRING,
          daysPaidDesc: EMPTY_STRING,
        },
        addressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          doNotVisit: false,
          isEdited: false,
        },
        verified: false,
        active: true,
        verifiedDate: EMPTY_STRING,
        employerDetailsId: null,
        overrideEnabled: false,
        isEditEnabled: false,
        verifiedBy: EMPTY_STRING,
        decisionEngineId: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
        isCancellable: true
      });
      const errorMessageDetails = Array(updatedEmpDetails.length).fill({
        basicInfo: {
          companyName: EMPTY_STRING,
          employmentYears: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          doNotCallEnabled: false,
          isEdited: false,
        },
        incomeVerification: {
          paydayFrequency: EMPTY_STRING,
          dayPaid: EMPTY_STRING,
          takeHomePay: EMPTY_STRING,
          isEdited: false,
          payDayFreqDesc: EMPTY_STRING,
          daysPaidDesc: EMPTY_STRING,
        },
        addressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          doNotVisit: false,
          isEdited: false,
        },
        verified: false,
        active: true,
        verifiedDate: EMPTY_STRING,
        employerDetailsId: null,
        overrideEnabled: false,
        isEditEnabled: false,
        verifiedBy: EMPTY_STRING,
        decisionEngineId: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
        isCancellable: true
      });
      console.log("After Error message", errorMessageDetails);
      setEmployerDetails(newEmployeeDetails);
      setUpdatedEmployerDetails(updatedEmpDetails);
      setErrorMessage(errorMessageDetails);
    }
  };

  return (
    <>
      <Grid container>
        {employerDetails.map((element: EmployerDetails, index: number) => (
          <>
            <Card
              className={`${classes.width100p} ${classes.mt10px} ${classes.card}`}
              key={index}
            >
              <CardContent className={`${classes.width100p}`}>
                <Grid container>
                  {!configurations.EnableCustomerVerificationPhaseTwo && (
                    <BasicInfo
                      value={
                        !employerDetails[index].isEditEnabled
                          ? element
                          : updatedEmployerDetails[index]
                      }
                      isVerifiedReq={isVerifiedCheckBoxRequired}
                      setterData={{
                        index,
                        employerDetails: !employerDetails[index].isEditEnabled
                          ? employerDetails
                          : updatedEmployerDetails,
                        setEmployerDetails: !employerDetails[index]
                          .isEditEnabled
                          ? setEmployerDetails
                          : setUpdatedEmployerDetails,
                        errorMessage,
                      }}
                    />
                  )}
                  <IncomeVerification
                    value={
                      !employerDetails[index].isEditEnabled
                        ? element
                        : updatedEmployerDetails[index]
                    }
                    setterData={{
                      index,
                      employerDetails: !employerDetails[index].isEditEnabled
                        ? employerDetails
                        : updatedEmployerDetails,
                      setEmployerDetails: !employerDetails[index].isEditEnabled
                        ? setEmployerDetails
                        : setUpdatedEmployerDetails,
                      errorMessage,
                    }}
                  />
                  {(!employerDetails[index].isEditEnabled ||
                    !updatedEmployerDetails[index].verified) &&
                  employerDetails[0].decisionEngineId ? (
                    <VerificationComponent
                      value={{
                        isButtonEnabled: false,
                        validateInputFields:
                          ((
                          employerDetails[index]?.incomeVerification
                            ?.paydayFrequency !== "" &&
                          (employerDetails[index]?.incomeVerification
                            ?.paydayFrequency !== "DAILY"
                            ? employerDetails[index]?.incomeVerification
                                ?.dayPaid !== ""
                            : true)) || incomeDoc),
                        editEnabled:
                          !updatedEmployerDetails[index].isEditEnabled,
                        actualVerifiedValue: updatedEmployerDetails[index],
                        updatedVerifiedValue: employerDetails[index],
                        setterFunction: setEmployerDetails,
                        isArrayOfObject: true,
                        index,
                        stateVariable: employerDetails as EmployerDetails[],
                      }}
                    />
                  ) : null}
                  {!configurations.customerVerificationOptimization ? (
                    <EmployerAddressInfo
                      value={element}
                      setterData={{
                        index,
                        employerDetails,
                        setEmployerDetails,
                        errorMessage,
                      }}
                    />
                  ) : null}
                  {configurations.customerVerificationOptimization ? (
                    <HandleEdit
                      value={
                        !employerDetails[index].isEditEnabled
                          ? element
                          : updatedEmployerDetails[index]
                      }
                      setterData={{
                        index,
                        employerDetails: !employerDetails[index].isEditEnabled
                          ? employerDetails
                          : updatedEmployerDetails,
                        setEmployerDetails: !employerDetails[index]
                          .isEditEnabled
                          ? setEmployerDetails
                          : setEmployerDetails,
                        errorMessage,
                        checkMandatoryFields,
                      }}
                    />
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
            {index == employerDetails.length - 1 ? (
              <Typography className={`${classes.racBlue}`}>
                <RACButton
                  className={`${classes.backgroundNone} ${classes.borderNone} ${classes.removeButtonHover}`}
                  onClick={() => setData()}
                >
                  + Add another Employment
                </RACButton>
              </Typography>
            ) : null}
          </>
        ))}
      </Grid>
    </>
  );
}
