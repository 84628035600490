/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import { Grid, Typography } from "@rentacenter/racstrap";
import { globalStyles } from './../../../jsStyles/globalStyles';
import {
  ContactInfoHeaderProps,
  EmployerDetailsProps,
} from "../../../interface/globalInterface";
import ContactInfoHeader from "../ContactInfoHeader";
import EmploymentDetails from "./EmploymentDetails";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";

export default function EmploymentInformation(props: {
  value: EmployerDetailsProps;
}) {
  const classes = globalStyles();
  const { configurations } = useContext(UpdateCustomerContext);
  const heading = configurations?.EnableCustomerVerificationPhaseTwo ? `Income` : `Employer's Information`
  const infoContent = configurations?.EnableCustomerVerificationPhaseTwo ? (
    <Grid className={`${classes.displayFlex} ${classes.flexDirectionColumn} ${classes.width100p}`}>
      <Typography component={'label'} className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}>Income Verification</Typography>
      <Typography component={'label'} className={`${classes.fontSize14px}`}>Verifies the customer’s income and their ability to make recurring rental payments</Typography>
    </Grid>
  ) : 'Recent paystub and 1 contact information for supervisor'
  const contentHeading: ContactInfoHeaderProps = {
    heading: heading,
    popupHeading: null,
    popupFunctionality: null,
    params: null,
    infoIcon: true,
    infoIconContent: infoContent,
    tooltipClass: configurations?.EnableCustomerVerificationPhaseTwo ? classes.toolTipStyle4 : undefined,
    tooltipArrowClass: configurations?.EnableCustomerVerificationPhaseTwo ? classes.arrowStyle1 : undefined
  };

  return (
    <Grid container className={`${classes.pt05p}`}>
      <Grid container> 
        <ContactInfoHeader value={contentHeading} />
        <EmploymentDetails value={props.value} />
      </Grid>
    </Grid>
  );
}
