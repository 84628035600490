/* eslint-disable */
//Import statements of react and racstarp packages
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  List,
  RACTabs,
  ListItem,
  RACButton,
  CardContent,
  RACModalCard,
  Typography,
  RACRadio,
  RACTextbox,
} from "@rentacenter/racstrap";

//Import statements of Icons
import { ReactComponent as WarningIcon } from "../../assets/images/Warning-icon.svg";
import { ReactComponent as CompletedIcon } from "../../assets/images/Completed-icon.svg";
import { ReactComponent as PendingIcon } from '../../assets/images/Pending-icon.svg';


//Import statements of Context
import { UpdateCustomerContext } from "../../context/UpdateCustomerContext";
import { FeatureFlagContext } from "../../../context/FeatureFlagContext";

//Import statements of utils
import { updateCustomerStyles } from "../../jsStyles/updateCustomerStyles";
import {
  CONTACT_INFORMATION_TEMPLATE,
  EMPLOYER_INFO_TEMPLATE,
  EMPTY_STRING,
  HIGHER_AUTHORITY_ROLES,
  RACPAD,
  STRING_YES,
} from "../../constants/constants";

//Import statements of Interfaces

import {
  Configurations,
  ReferenceInformation as ReferenceInfo,
} from "../../interface/updateCustomerInterface";

import {
  DropdownOptions,
  EmployerDetails,
  EmployerDetailsProps,
  IdentityDetailsInfo,
  IdentityDetailsProps,
  PathParams,
  ReferenceDetail,
  ReferenceDetailsProps,
  tabList,
} from "../../interface/globalInterface";

import {
  getAlertsList,
  getDropdownData,
  getCustomerAlerts,
  getCustomerAddresses,
  getStoreConfigurations,
  getCustomerInformation,
  getCustomerInactivePhone,
  getCustomerLegalHoldStatus,
  getAgreementDetails,
  getClubDetails,
} from "../../apiHandler/updateCustomerApiHandler";

//Import statements of JSX Components
import ScreenLoader from "../sharedComponents/ScreenLoader";
import UpdateCustomerHeader from "./microComponents/UpdateCustomerHeader";
import CustomerInformation from "../updateCustomer/screens/CustomerInformation";
import EmploymentInformation from "../sharedComponents/EmployeeInformation/EmploymentInfo";
import ResidenceInformation from "../sharedComponents/ResidenceInformation/ResidenceInfo";
import ReferenceInformation from "../sharedComponents/ReferenceInformation/ReferenceInformation";
import VehicleInfoTab from "./screens/VehicleInformationTab";
import AgreementHistoryTab from "./screens/AgreementHistoryTab";
import DEHistoryTab from "./screens/DEHistoryTab";
import UpdateCustomerFooter from "../updateCustomer/microComponents/UpdateCustomerFooter";
import IdentityInformation from "../sharedComponents/IdentityInformation/IdentityInfo";
import { formatText, getCoworkerNames } from "../sharedComponents/Utils";
import { getCurrentUser, SendIntellicheck } from "../../api/user";
import SecondFactor from "../../../racstrapComponent/manageCustomer/secondAuthen";
import { VerificationNotrequired } from "../sharedComponents/VerificationNotRequired";
import { AnyARecord } from "dns";
import { DynamicAlertPopup } from "../sharedComponents/DynamicAlertPopup";
import { ReactComponent as SuccessIcon } from '../../../assets/images/success-icon.svg';


export function UpdateCustomer() {
  const classes = updateCustomerStyles();
  const { customerId, pageType } = useParams<PathParams>();
  const { featureFlagDetails, setFeatureFlagDetails } = useContext(FeatureFlagContext);
  let { verificationNotrequiredEnable, setVerificationNotrequiredEnable, verificationNotrequiredPayload, setVerificationNotrequiredPayload, notRequiredprovided, setnotRequiredprovided, setVerificationDocuments } = useContext(UpdateCustomerContext)
  const [showSecondLevlAuth, setShowSecondLevelAuth] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [performanceFlag, setPerformanceflag]= useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<string>('TEXT');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [completedPopup, setCompletedPopup] = useState<boolean>(false);

  let {
    screenLoader,
    setScreenLoader,
    //Dropdowns
    setGovtIDTypeOptions,
    setStateOptions,
    setSaluationOptions,
    setSuffixOptions,
    setMethodOfContactOptions,
    setPhoneTypeOptions,
    setBestTimeToCallOptions,
    setRouteOptions,
    setLegalHoldOptions,
    setTaxStatusOptions,
    setRelationshipOptions,
    setWeeklyDropdownOptions,
    setMonthlyDropdownOptions,
    setSemiMonthlyDropdownOptions,
    setPayDayFrequencyOptions,
    setCustomerBasicInformation,
    setSkipStolenHardStatus,
    setLegalInformation,
    setCommunicationInformation,
    setVehicleInformation,
    setContactInformation,
    setInactivePhone,
    setTaxexemptInformation,
    setFederalTaxId,
    setResidenceInfo,
    setReferenceInformation,
    setAssignedAlerts,
    setAlertsOptions,
    setAddress,
    setDisableWebLink,
    setEmployerDetails,
    employerDetails,
    setEditedAddress,
    setInActiveAddress,
    setActiveAddress,
    setConfigurations,
    setGetCustomerInfoResponse,
    setUpdatedCustomerBasicInfo,
    setUpdatedCommunicationInformation,
    identityDetails,
    setIdentityDetails,
    actualIdentityDetails,
    setActualIdentityDetials,
    identityErrorMessage,
    setIdentityErrorMessage,
    getApprovalRes,
    setGetApprovalRes,
    setUpdatedContactInfo,
    setContactInfoErrorMessage,
    setUpdatedEmployerDetails,
    employerDetailsErrorMessage,
    setEmployerDetailsErrorMessage,
    setPrimaryAddress,
    setCoworkerIds,
    activetab,
    setactivetab,
    setCurrentRole,
    setAgreementInformation,
    setAgrtransferinit,
    setClubInfo,
    setCurrentEmpId,
    setCustomerAddress,
    referenceInformation,
    currentRole,
    legalHoldedScreen,
    setLegalHoldedScreen,
    setUpdatedReferenceDetails,
    setReferenceErrorMessage,
    referenceErrorMessage,
    configurations,
    openSendDigitalOrderFormLink, 
    setOpenSendDigitalOrderFormLink,
    currentEmpId,
    setErrorMessage,
    errorMessage,
    setIncomeSourceDropDownOptions
  } = useContext(UpdateCustomerContext);

  useEffect(() => {
    setLoader(screenLoader);
  }, [screenLoader]);

  useEffect(() => {
    setGetApprovalRes(getApprovalRes);
  }, [getApprovalRes]);

  /**
   * Purpose of the function:
   * To fetch the data required for the customer updation flow is fetched using API and stored in respective context
   */
  const renderUpdateCustomerScreen = async (): Promise<void> => {
    try {
      if (customerId && pageType && pageType == "customer") {
        //Loader on whole Screen and dropdown starts here
        setScreenLoader(true);

        // The configuration call is made as seperate because VERIFICATION_DAYS is required immediately for further API Manipulation.
        const configurationResponse: Configurations =
          await getStoreConfigurations();
        setConfigurations(configurationResponse);

        console.log('featureFlagDetails', featureFlagDetails, configurationResponse);
        const featureDetails = {
          ...featureFlagDetails, 
          CustomerVerificationOptimization: configurationResponse?.customerVerificationOptimization ? '1' : '0'
        }
        console.log('featureDetails', featureFlagDetails, featureDetails);
        setFeatureFlagDetails(featureDetails);

        /**
         * All the required data from api call are fetched and stored below.
         * The seperate API call for dropdown is avoided as there is flickering in the page while dropdown loaded
         */
        const [
          legalHoldResponse,
          customerInformationResponse,
          inactivePhoneResponse,
          addressResponse,
          dropdownResponse,
          alertsListResponse,
          assignedAlerts,
          currentUserRole,
          agreementDetails,
          clubInformation,
        ] = await Promise.all([
          getCustomerLegalHoldStatus(customerId),
          getCustomerInformation(
            customerId,
            configurationResponse.customerVerificationOptimization,
            setVerificationDocuments
          ),
          getCustomerInactivePhone(customerId),
          getCustomerAddresses(customerId),
          getDropdownData(),
          getAlertsList(customerId),
          getCustomerAlerts(customerId),
          getCurrentUser(),
          getAgreementDetails(customerId),
          getClubDetails(customerId),
        ]);

        //Destructing the mass data, which is exported in the same manner.
        const [
          basicInformation,
          skipHardInformation,
          taxExemptInformation,
          communicationInformation,
          residenceInformation,
          vehicleInformation,
          contactInformation,
          referenceInformation,
          employmentDetails,
          customerInfoResponse,
          identityDetailsInfo
        ] = customerInformationResponse;

        const [
          govtIDTypeOptions,
          stateOptions,
          saluationOptions,
          suffixOptions,
          bestTimeToCallOptions,
          phoneTypeOptions,
          preferredContactOptions,
          routeOptions,
          legalHoldOptions,
          taxExemptStatusOptions,
          relationshipOptions,
          payDayOptions,
          weeklyDropdownOptions,
          monthlyDropdownOptions,
          semiMonthlyDropdownOptions,
          incomeSourceOptions
        ]: Array<DropdownOptions[]> = dropdownResponse;

        getApprovalRes =
          customerInfoResponse?.data?.GetApproval?.value
            ?.customerApprovalDetails;

        //Whole response of GetCustomer
        setGetApprovalRes(getApprovalRes);
        setGetCustomerInfoResponse(customerInfoResponse?.data);

        if (customerInfoResponse?.data?.GetCustomer?.value?.addresses?.length) {
          setCustomerAddress(customerInfoResponse?.data?.GetCustomer?.value?.addresses);
        }

        /**** Customer Alert ****/
        setAlertsOptions(alertsListResponse);
        setAssignedAlerts(assignedAlerts);

        /**** Basic Information ****/
        setCustomerBasicInformation(basicInformation);
        setUpdatedCustomerBasicInfo(basicInformation);
        setGovtIDTypeOptions(govtIDTypeOptions);
        setStateOptions(stateOptions);
        setSaluationOptions(saluationOptions);
        setSuffixOptions(suffixOptions);
        if (basicInformation.oktaId) {
          setDisableWebLink(true);
        }

        /**** Address Information ****/
        setAddress(addressResponse);
        setEditedAddress(addressResponse.activeAddresses);
        setActiveAddress(addressResponse.activeAddresses);
        setInActiveAddress(addressResponse.inActiveAddresses);
        setPrimaryAddress(addressResponse.primaryAddressArray);

        /**** Contact Information ****/
        setContactInformation(contactInformation);
        setUpdatedContactInfo(contactInformation);
        setContactInfoErrorMessage(
          Array(contactInformation.length).fill({
            ...CONTACT_INFORMATION_TEMPLATE,
          })
        );
        setInactivePhone(inactivePhoneResponse);
        setBestTimeToCallOptions(bestTimeToCallOptions);
        setPhoneTypeOptions(phoneTypeOptions);

        /**** Communication Information ****/
        setCommunicationInformation(communicationInformation);
        setUpdatedCommunicationInformation(communicationInformation);
        setMethodOfContactOptions(preferredContactOptions);
        setRouteOptions(routeOptions);

        /**** Legal ****/
        setLegalInformation(legalHoldResponse);
        setSkipStolenHardStatus(skipHardInformation);
        setLegalHoldOptions(legalHoldOptions);

        /**** Tax Exempt ****/
        setFederalTaxId(taxExemptInformation.federalTaxId);
        setTaxexemptInformation(taxExemptInformation);
        setTaxStatusOptions(taxExemptStatusOptions);

        /**Identity Info */
        setIdentityDetails(identityDetailsInfo);
        setActualIdentityDetials(identityDetailsInfo);

        /**** Employment Information ****/
        setEmployerDetails(employmentDetails);
        setUpdatedEmployerDetails(employmentDetails);
        setEmployerDetailsErrorMessage(
          Array(employmentDetails.length).fill({
            basicInfo: {
              companyName: EMPTY_STRING,
              employmentYears: EMPTY_STRING,
              phoneNumber: EMPTY_STRING,
              doNotCallEnabled: false,
              isEdited: false,
            },
            incomeVerification: {
              paydayFrequency: EMPTY_STRING,
              dayPaid: EMPTY_STRING,
              takeHomePay: EMPTY_STRING,
              isEdited: false,
            },
            addressInfo: {
              addressLine1: EMPTY_STRING,
              addressLine2: EMPTY_STRING,
              zip: EMPTY_STRING,
              city: EMPTY_STRING,
              state: EMPTY_STRING,
              doNotVisit: false,
              isEdited: false,
            },
            verified: false,
            active: true,
            verifiedDate: EMPTY_STRING,
            employerDetailsId: null,
            overrideEnabled: false,
            isEditEnabled: false,
            verifiedBy: EMPTY_STRING,
            decisionEngineId: EMPTY_STRING,
            verifiedSource: EMPTY_STRING,
            verifyCode: EMPTY_STRING,
          })
        );
        setPayDayFrequencyOptions(payDayOptions);
        setWeeklyDropdownOptions(weeklyDropdownOptions);
        setMonthlyDropdownOptions(monthlyDropdownOptions);
        setSemiMonthlyDropdownOptions(semiMonthlyDropdownOptions);
        setIncomeSourceDropDownOptions(incomeSourceOptions);

        /**** Residence Information ****/
        setResidenceInfo(residenceInformation);

        /**** Reference Information ****/
        setReferenceInformation(referenceInformation);
        setUpdatedReferenceDetails(referenceInformation);
        setReferenceErrorMessage(
          Array(referenceInformation.length).fill({
            referenceInfo: {
              relativeFirstName: EMPTY_STRING,
              relativeLastName: EMPTY_STRING,
              referenceName: EMPTY_STRING,
              phoneNumber: EMPTY_STRING,
              bestTimeToCall: EMPTY_STRING,
              relationShip: EMPTY_STRING,
              doNotCall: false,
              isEdited: false,
              relationshipTypeDesc: EMPTY_STRING,
            },
            referenceAddressInfo: {
              addressLine1: EMPTY_STRING,
              addressLine2: EMPTY_STRING,
              zip: EMPTY_STRING,
              city: EMPTY_STRING,
              state: EMPTY_STRING,
              isEdited: false,
            },
            verified: false,
            verifiedDate: EMPTY_STRING,
            active: false,
            referenceDetailsId: EMPTY_STRING,
            decisionEngineId: EMPTY_STRING,
            isEditEnabled: false,
            verifyCode: EMPTY_STRING,
            verifiedCV: EMPTY_STRING,
            verifiedBy: EMPTY_STRING,
            verifiedOn: EMPTY_STRING,
            verifiedSource: EMPTY_STRING,
          })
        );

        setRelationshipOptions(relationshipOptions);

        /**** Vehicle Information ****/
        setVehicleInformation(vehicleInformation);

        /**** Transfer Agreement details  ****/
        setAgreementInformation(agreementDetails);
        setAgrtransferinit(agreementDetails.transferInitiated);

        /**** Club Information ****/
        setClubInfo(clubInformation);

        setPerformanceflag(true);

        //Loader on whole Screen and dropdowns stops here
        setScreenLoader(false);

        /**Current User Role Adding*/

        const coWorkerId = currentUserRole?.status == 200
          ? currentUserRole?.data?.employeeId
          : "";
        setCurrentEmpId(coWorkerId);
        /**Triggers the function which is form the coworker name from the GetApproval Resp and bind in to add the */
        if (configurationResponse.customerVerificationOptimization)
          await getCoworkerNames(
            setCoworkerIds,
            coWorkerId,
            setCurrentRole,
            customerInfoResponse?.data?.GetApproval?.value
              ?.customerApprovalDetails
          );

        if (
          legalHoldResponse.legalHoldStatus &&
          HIGHER_AUTHORITY_ROLES.includes(currentRole)
        ) {
          setShowSecondLevelAuth(true);
        } else if (
          legalHoldResponse.legalHoldStatus &&
          !HIGHER_AUTHORITY_ROLES.includes(currentRole)
        ) {
          setLegalHoldedScreen(true);
        }
      }
    } catch (error: any) {
      setScreenLoader(false);
      console.log("Error in renderUpdateCustomerScreen: ", error?.message);
    }
  };

  useEffect(() => {
    renderUpdateCustomerScreen();
  }, []);

  const employerDetailsProps: EmployerDetailsProps = {
    employerDetails: employerDetails,
    setEmployerDetails: setEmployerDetails,
    errorMessage: employerDetailsErrorMessage,
    setErrorMessage: setEmployerDetailsErrorMessage,
  };

  const identityProps: IdentityDetailsProps = {
    identityDetails: identityDetails,
    setIdentityDetails: setIdentityDetails,
    errorMessage: identityErrorMessage,
    setErrorMessage: setIdentityErrorMessage,
    actualIdentityDetails,
    setActualIdentityDetials,
    setIdentityErrorMessage
  };

  const ReferenceProps: ReferenceDetailsProps = {
    referenceInfo: referenceInformation,
    setReferenceInfo: setReferenceInformation,
    errorMessage: referenceErrorMessage,
    setErrorMessage: setReferenceErrorMessage,
  };

  // Function for determinig the Icons for Each Tabs,

  const customerTabIcon = (value?: any) => {
    if (value) {

      if (value?.verificationStatus == 'PENDREW') {
        return (
          <>
            <PendingIcon style={{ marginRight: "5px", width: "22px" }} />
          </>
        );
      }
      if (value?.verifyRequired == STRING_YES && (value?.verified == "N" || value?.verificationStatus == "PEND")) {
        return (
          <>
            <WarningIcon style={{ marginRight: "5px", width: "22px" }} />
          </>
        );
      } else if ((value?.verified == STRING_YES || value?.verificationStatus == 'VERIFIED')) {
        return (
          <>
            <CompletedIcon style={{ marginRight: "5px", width: "22px" }} className={classes.successicon} />{" "}
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return;
    }
  };

  const verificationNotRequiredCompletedFn = async (type?: any) => {
    setVerificationNotrequiredEnable(false);
    setnotRequiredprovided((prevArray: any) => [...prevArray, type]);
    setactivetab(type);
  };

  const verificationNotRequiredCancelledFn = async (
    type?: any,
    verificationDocuments?: any
  ) => {
    setVerificationNotrequiredEnable(false);
  };

  const CustomerInfoTabClick = async (type?: any, verificationDocuments?: any) => {
    if (verificationDocuments && verificationDocuments.verifyRequired == 'N' && verificationDocuments.verified == 'N' && !notRequiredprovided.includes(type)) {
      setVerificationNotrequiredEnable(true)
      verificationNotrequiredPayload = { verificationNotrequiredPayload, activetabType: type, verifyType: verificationDocuments.verifyType, verificationDocument: verificationDocuments }
      setVerificationNotrequiredPayload(verificationNotrequiredPayload)
    }
    else {
      setactivetab(type)
    }
  }

  const openSendTextPopup = () => {
    return (
      <Grid className={`${classes.p25px}`}>
        <Typography className={`${classes.pb10px}`}>
          How do you want the share the verification link?
        </Typography>
        <Grid className={`${classes.pb10px}`}>
          <RACRadio
            value={`Text`}
            label={`Text`}
            checked={selectedField == "TEXT"}
            onClick={() => {
              setSelectedField("TEXT");
            }}
          />
          <RACRadio
            value={`Email`}
            label={`Email`}
            checked={selectedField == "EMAIL"}
            onClick={() => {
              setSelectedField("EMAIL");
            }}
          />
        </Grid>
        <Grid>
          {selectedField == "TEXT" && (
            <RACTextbox
              inputlabel="Text"
              required={true}
              type={"text"}
              maxlength={14}
              value={
                mobileNumber.length === 10
                ? `(${mobileNumber.slice(0, 3)}) ${mobileNumber.slice(
                  3,
                  6
                )}-${mobileNumber.slice(6, 10)}`
                : mobileNumber
              }
              OnChange={(e: any) => {
                const cleaned = ("" + e.target.value).replace(/\D/g, "");
                setMobileNumber(cleaned);
              }}
              OnKeydown={(e) => {
                if (e.key == "Enter" && mobileNumber.length >= 10) {
                  sendDigitalOrderFormLink();
                }
              }}
              errorMessage={errorMessage}
            />
          )}

          {selectedField == "EMAIL" && (
            <RACTextbox
              inputlabel="Email"
              required={true}
              type={"text"}
              maxlength={50}
              value={email}
              OnChange={(e: any) => {
                setEmail(e.target.value);
              }}
              errorMessage={errorMessage}
            />
          )}
        </Grid>
        <Grid className={`${classes.displayFlex} ${classes.justifyContentRight} ${classes.mt30px}`}>
          <RACButton
            variant="outlined"
            color="primary"
            id="cancel"
            className={`${classes.buttonsPadding} ${classes.mr16}`}
            onClick={() => setOpenSendDigitalOrderFormLink(false)}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            id="save"
            className={`${classes.buttonsPadding} ${classes.mr25p}`}
            onClick={() => {
              sendDigitalOrderFormLink()
            }}
          >
            Send
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  const sendDigitalOrderFormLink = async () => {
    if (validateFields()) {
      setLoader(true);
      const payload: any = {
        isDigitalOrderFlow: true,
        approvalId: identityDetails?.decisionEngineId,
        source: RACPAD,
        userId: currentEmpId,
        storeNumber: window.sessionStorage.getItem("storeNumber"),
        ...(selectedField === "TEXT"
          ? { phoneNumber: mobileNumber }
          : { emailAddress: email }),
        sendLinkToCustomer: true,
      };

      const sendDigitalOrderFormLinkResp = await SendIntellicheck(payload);

      console.log("SendDigitalOrderFormResp", sendDigitalOrderFormLinkResp);

      if(sendDigitalOrderFormLinkResp?.status == 200 && sendDigitalOrderFormLinkResp?.data?.digitalOrderFormURL) {
        setCompletedPopup(true);
        setEmail('');
        setMobileNumber('');
      } else {
        setErrorPopup(true);
      }
      setOpenSendDigitalOrderFormLink(false)
      setLoader(false);
    }
  }

  const validateFields = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (selectedField == "TEXT" && mobileNumber.length >= 10) {
      setErrorMessage('');
      return true;
    } else if(selectedField == "TEXT" && mobileNumber.length < 10) {
      setErrorMessage('Enter Valid Mobile Number');
      return false;
    }
    if (selectedField === "EMAIL" && emailPattern.test(email)) {
      setErrorMessage('');
      return true;
    } else if(selectedField === "EMAIL" && !emailPattern.test(email)) {
      setErrorMessage('Enter Valid Email Address');
      return false;
    }
  }

  const successfullPopupFn = () => {
    return (
      <div>

        <Grid item md={12} className={classes.textCenter}>
          <SuccessIcon></SuccessIcon>
          <Typography style={{ marginTop: '13px', fontFamily: 'OpenSans-bold', fontSize: '17px' }}>
            Message Sent Successfully
          </Typography>
        </Grid>
        <Grid style={{ textAlign: 'center', marginTop: '23px' }}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setCompletedPopup(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div >
    );
  };

  return (
    <>
      {loader ? <ScreenLoader /> : null}
      {verificationNotrequiredEnable ? (
        <VerificationNotrequired
          customerId={String(customerId)}
          activetabType={verificationNotrequiredPayload.activetabType}
          documentType={verificationNotrequiredPayload.verifyType}
          verificationNotRequiredCancelledFn={() => verificationNotRequiredCancelledFn()}
          verificationNotRequiredCompletedFn={(e: any) => verificationNotRequiredCompletedFn(e)}
        />) : null}

      {showSecondLevlAuth ? (
        <SecondFactor
          key={"SLAComponent"}
          setTwoFactorCancelClick={() => { }}
          setTwoFactorCompleted={(e: any) => {
            setShowSecondLevelAuth(false);
          }}
          moduleName={"Assign Alert"}
          currentRole={currentRole}
        />
      ) : null}

      <UpdateCustomerHeader />

      {featureFlagDetails?.CustomerVerificationOptimization == 1 ? (
        <>
          <Grid container className={classes.mb3}>
            <Card
              className={`${classes.card} ${classes.w100} ${classes.mb3} ${classes.borderRadiusZero}`}
            >
              <CardContent
                className={`${classes.headerTab} ${classes.floatLeft} ${classes.w100} ${classes.py0}`}
                style={{ display: 'flex' }}
              >
                <Grid item className={`${classes.floatLeft} ${classes.w100}`}>
                  <Box className={classes.floatLeft}>
                    <List className={`${classes.racpadTab} ${classes.py0}`}>
                      <ListItem
                        className={
                          activetab == 0
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        onClick={() => {
                          CustomerInfoTabClick(tabList.CUSTOMERINFO);
                        }}
                      >
                        Customer Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 1
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        onClick={() => {
                          CustomerInfoTabClick(
                            tabList.IDENTITY,
                            getApprovalRes?.verificationDocuments &&
                            getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "ID"
                            )
                          );
                        }}
                        style={{
                          borderLeft: "1px solid #969696",
                          color:
                            getApprovalRes?.verificationDocuments &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "ID"
                              )
                                ?.verifyRequired == "N" &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "ID"
                              )
                                ?.verified == "N"
                              ? "gray"
                              : "black",
                        }}
                      >
                        {customerTabIcon(
                          getApprovalRes &&
                            getApprovalRes?.verificationDocuments
                            ? getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "ID"
                            )
                            : ""
                        )}
                        Identity
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 2
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        onClick={() => {
                          CustomerInfoTabClick(
                            tabList.RESIDENCE,
                            getApprovalRes?.verificationDocuments &&
                            getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "RESIDENCE"
                            )
                          );
                        }}
                        style={{
                          color:
                            getApprovalRes?.verificationDocuments &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "RESIDENCE"
                              )?.verifyRequired == "N" &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "RESIDENCE"
                              )?.verified == "N"
                              ? "gray"
                              : "black",
                        }}
                      >
                        {customerTabIcon(
                          getApprovalRes &&
                            getApprovalRes?.verificationDocuments
                            ? getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "RESIDENCE"
                            )
                            : ""
                        )}
                        Residence
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 3
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        style={{
                          color:
                            getApprovalRes?.verificationDocuments &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "EMPLOYMENT"
                              )?.verifyRequired == "N" &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "EMPLOYMENT"
                              )?.verified == "N"
                              ? "gray"
                              : "black",
                        }}
                        onClick={() => {
                          CustomerInfoTabClick(
                            tabList.EMPLOYMENTINFO,
                            getApprovalRes?.verificationDocuments &&
                            getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "EMPLOYMENT"
                            )
                          );
                        }}
                      >
                        {customerTabIcon(
                          getApprovalRes &&
                            getApprovalRes?.verificationDocuments
                            ? getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "EMPLOYMENT"
                            )
                            : ""
                        )}
                        {`${configurations.EnableCustomerVerificationPhaseTwo ? 'Income' : 'Employment Information'}`}
                      </ListItem>

                      <ListItem
                        className={
                          activetab == 4
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        onClick={() => {
                          CustomerInfoTabClick(
                            tabList.REFERENCE,
                            getApprovalRes?.verificationDocuments &&
                            getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "REFERENCES"
                            )
                          );
                        }}
                        style={{
                          borderRight: "1px solid #969696",
                          color:
                            getApprovalRes?.verificationDocuments &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "REFERENCES"
                              )?.verifyRequired == "N" &&
                              getApprovalRes?.verificationDocuments?.find(
                                (element: any) => element.verifyType == "REFERENCES"
                              )?.verified == "N"
                              ? "gray"
                              : "black",
                        }}
                      >
                        {customerTabIcon(
                          getApprovalRes &&
                            getApprovalRes?.verificationDocuments
                            ? getApprovalRes?.verificationDocuments?.find(
                              (element: any) => element.verifyType == "REFERENCES"
                            )
                            : ""
                        )}
                        Reference
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 5
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        onClick={() => {
                          CustomerInfoTabClick(tabList.AGREEMENTHISTORY);
                        }}
                      >
                        Agreement History
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 6
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        onClick={() => {
                          CustomerInfoTabClick(tabList.DEHISTORY);
                        }}
                      >
                        DE History
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                {configurations.EnableCustomerVerificationPhaseTwo && (
                  <Grid style={{ marginTop: "8px" }}>
                    <RACButton
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "initial", whiteSpace: "nowrap" }}
                      onClick={() => {
                        setOpenSendDigitalOrderFormLink(true);
                      }}
                    >
                      Send Verification Link
                    </RACButton>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : (
        <Grid
          key={"TabComponentContainer"}
          id={"TabComponentContainer"}
          container
          className={classes.racTabStyle}
        >
          <RACTabs
            key={"TabComponent"}
            id={"TabComponent"}
            tabs={[
              "Customer Information",
              `${configurations.EnableCustomerVerificationPhaseTwo ? 'Income' : 'Employment Information'}`,
              "Residence",
              "Reference",
              "Vehicle Information",
              "Agreement History",
              "DE History",
            ]}
            contentForTabs={
              !legalHoldedScreen
                ? [
                    <CustomerInformation />,
                    <EmploymentInformation value={employerDetailsProps} />,
                    <ResidenceInformation />,
                    // <ReferenceInformation value={referenceInformation} />,
                    <VehicleInfoTab />,
                    <AgreementHistoryTab />,
                    <DEHistoryTab />,
                  ]
                : [
                    <CustomerInformation />,
                    <></>,
                    <></>,
                    <></>,
                    <></>,
                    <></>,
                    <DEHistoryTab />,
                  ]
            }
          />
        </Grid>
      )}
      {activetab == 0 && performanceFlag ? <CustomerInformation /> : null}
      {activetab == 1 && !legalHoldedScreen ? (
        <IdentityInformation value={identityProps} />
      ) : null}
      {activetab == 3 && !legalHoldedScreen ? (
        <EmploymentInformation value={employerDetailsProps} />
      ) : null}
      {activetab == 2 && !legalHoldedScreen ? <ResidenceInformation /> : null}
      {activetab == 4 && !legalHoldedScreen ? (
        <ReferenceInformation value={ReferenceProps} />
      ) : null}
      {activetab == 5 && !legalHoldedScreen ? <AgreementHistoryTab /> : null}
      {activetab == 6 ? <DEHistoryTab /> : null}
      <Grid style={{ padding: "2.6rem" }} />
      <UpdateCustomerFooter />
      <RACModalCard
        isOpen={openSendDigitalOrderFormLink}
        closeIcon={true}
        maxWidth="xs"
        borderRadius={"25px !important"}
        title="Send Verification Link"
        onClose={() => {
          setOpenSendDigitalOrderFormLink(false)
        }}
      >
        {openSendTextPopup()}
      </RACModalCard>
      {errorPopup && (
        <DynamicAlertPopup
          alertMessage="Something Went Wrong"
          alertType="ERROR"
          closeIcon={true}
          onCloseFn={() => {
            setErrorPopup(false);
            setOpenSendDigitalOrderFormLink(true)
          }}
        ></DynamicAlertPopup>
      )}
      <RACModalCard
          isOpen={completedPopup}
          closeIcon={true}
          maxWidth="xs"
          borderRadius={'25px !important'}
          onClose={() => setCompletedPopup(false)}
        >
          {successfullPopupFn()}
        </RACModalCard>
    </>
  );
}
