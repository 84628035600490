/* eslint-disable */

import React, { useContext } from "react";
import {
  Grid,
  RACButton,
  RACCheckBox,
  RACSelect,
  RACTextbox,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { DropdownOptions } from "../../../interface/globalInterface";
import { dropdownOptions } from "../../../constants/constants";
import {
  formatMobileNumber,
  formatText,
  isNotNullVariable,
  updateReferenceInfo,
} from "../Utils";
import moment from "moment";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";

export default function ReferenceDetails({
  value,
  setterData,
}: any) {
  const classes = globalStyles();
  const Agrtransferinit = false;
  const { index, referenceInfo, setReferenceInfo, editEnabled } = setterData;
  const { configurations, relationshipOptions, setUpdatedReferenceInfo, referenceErrorMessage, bestTimeToCallOptions } = useContext(UpdateCustomerContext);
  console.log('relationshipOptions', relationshipOptions);
  const verificationOptimizationOptions = relationshipOptions.filter((element: DropdownOptions) => element.value != 'REL');
  return (
    <Grid
      container
      md={12}
      className={
        configurations.customerVerificationOptimization
          ? `${classes.mt10px}`
          : ``
      }
    >
      {!configurations.customerVerificationOptimization ? (
        <Grid container md={12}>
          <Grid item md={10}></Grid>
          <Grid item md={1}>
            <RACCheckBox
              label="Verified"
              size="small"
              onChange={(e: any) => {
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    ...setterData.referenceInfo[setterData.index],
                    verified: e.target.checked,
                    verifiedDate: e.target.checked
                      ? moment().format("YYYY-MM-DD")
                      : "",
                  }
                );
              }}
              disabled={
                Agrtransferinit
                  ? true
                  : setterData.referenceInfo[setterData.index].active
              }
              checked={isNotNullVariable(
                setterData.referenceInfo[setterData.index].verifiedDate
              )}
            />
          </Grid>
          <Grid item className={classes.alignCheckBox}>
            <RACCheckBox
              label="Active"
              size="small"
              onChange={(e: any) => {
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    ...setterData.referenceInfo[setterData.index],
                    active: e.target.checked,
                    verifiedDate: e.target.checked
                      ? moment().format("YYYY-MM-DD")
                      : "",
                  }
                );
              }}
              checked={setterData.referenceInfo[setterData.index].active}
              disabled={Agrtransferinit}
            />
          </Grid>
        </Grid>
      ) : null}

      {configurations?.EnableCustomerVerificationPhaseTwo ?
        <Grid container md={12}>
          <Grid container spacing={2} md={12}>
            <Grid item md={3} lg={2}>
              <RACTextbox
                required={true}
                type={"text"}
                inputlabel={"Reference Name"}
                inputLabelClassname={classes.textWrapStyle}
                OnChange={(e: any) =>
                  updateReferenceInfo(
                    setterData.index,
                    setterData.referenceInfo,
                    setterData.setReferenceInfo,
                    {
                      referenceInfo: {
                        ...setterData.referenceInfo[setterData.index].referenceInfo,
                        referenceName: formatText(e.target.value),
                      },
                    }
                  )
                }
                maxlength={25}
                value={value?.referenceName || ""}
                disabled={
                  Agrtransferinit || editEnabled
                    ? true
                    : !setterData.referenceInfo[setterData.index].active
                }
                errorMessage={referenceErrorMessage[index].referenceInfo.referenceName}
              />
            </Grid>

            <Grid item md={3} lg={2}>
              <RACTextbox
                isCurrency={false}
                required={setterData.referenceInfo[setterData.index].active}
                type={
                  setterData.referenceInfo[setterData.index].referenceInfo.doNotCall
                    ? "password"
                    : "text"
                }
                inputlabel="Phone Number"
                OnChange={(e: any) =>
                  updateReferenceInfo(
                    setterData.index,
                    setterData.referenceInfo,
                    setterData.setReferenceInfo,
                    {
                      referenceInfo: {
                        ...setterData.referenceInfo[setterData.index].referenceInfo,
                        phoneNumber: e.target.value,
                      },
                    }
                  )
                }
                maxlength={14}
                value={formatMobileNumber(value?.phoneNumber) || ""}
                disabled={
                  Agrtransferinit || editEnabled
                    ? true
                    : !setterData.referenceInfo[setterData.index].active ||
                    setterData.referenceInfo[setterData.index].referenceInfo
                      .doNotCall
                }
                errorMessage={referenceErrorMessage[index].referenceInfo.phoneNumber}
              />
            </Grid>
            <Grid item md={3} lg={2}>
              <RACSelect
                inputLabel="Relationship"
                options={configurations.customerVerificationOptimization ? verificationOptimizationOptions : relationshipOptions}
                isDisabled={Agrtransferinit || editEnabled}
                defaultValue={value?.relationShip}
                required={setterData.referenceInfo[setterData.index].active}
                loading={relationshipOptions.length == 0 ? true : false}
                onChange={(e: any) =>
                  updateReferenceInfo(
                    setterData.index,
                    setterData.referenceInfo,
                    setterData.setReferenceInfo,
                    {
                      referenceInfo: {
                        ...setterData.referenceInfo[setterData.index].referenceInfo,
                        relationShip: e.target.value,
                        relationshipTypeDesc: e.target.name
                      },
                    }
                  )
                }
                errorMessage={referenceErrorMessage[index].referenceInfo.relationShip}
              />
            </Grid>
            <Grid item md={4} lg={2} className={classes.mt19pxRem}>
              <RACCheckBox
                label="Do Not Call"
                size="small"
                onChange={(e: any) =>
                  updateReferenceInfo(
                    setterData.index,
                    setterData.referenceInfo,
                    setterData.setReferenceInfo,
                    {
                      referenceInfo: {
                        ...setterData.referenceInfo[setterData.index].referenceInfo,
                        doNotCall: e.target.checked,
                      },
                    }
                  )
                }
                checked={value?.doNotCall}
                disabled={
                  Agrtransferinit || editEnabled
                    ? true
                    : !setterData.referenceInfo[setterData.index].active
                }
              />
            </Grid>

          </Grid>
        </Grid> :


        <Grid container spacing={2}>
          <Grid item md={3} lg={2}>
            <RACTextbox
              required={true}
              type={"text"}
              inputlabel={configurations.customerVerificationOptimization ? 'Reference First Name' : "Parent/Relative First Name"}
              inputLabelClassname={classes.textWrapStyle}
              OnChange={(e: any) =>
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    referenceInfo: {
                      ...setterData.referenceInfo[setterData.index].referenceInfo,
                      relativeFirstName: formatText(e.target.value),
                    },
                  }
                )
              }
              maxlength={25}
              value={value?.relativeFirstName || ""}
              disabled={
                Agrtransferinit || editEnabled
                  ? true
                  : !setterData.referenceInfo[setterData.index].active
              }
              errorMessage={referenceErrorMessage[index].referenceInfo.relativeFirstName}
            />
          </Grid>
          <Grid item md={3} lg={2}>
            <RACTextbox
              isCurrency={false}
              required={setterData.referenceInfo[setterData.index].active}
              type={"text"}
              inputlabel={configurations.customerVerificationOptimization ? 'Reference Last Name' : "Parent/Relative Last Name"}
              OnChange={(e: any) =>
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    referenceInfo: {
                      ...setterData.referenceInfo[setterData.index].referenceInfo,
                      relativeLastName: formatText(e.target.value),
                    },
                  }
                )
              }
              maxlength={25}
              value={value?.relativeLastName || ""}
              disabled={
                Agrtransferinit || editEnabled
                  ? true
                  : !setterData.referenceInfo[setterData.index].active
              }
              errorMessage={referenceErrorMessage[index].referenceInfo.relativeLastName}
            />
          </Grid>
          <Grid item md={3} lg={2}>
            <RACTextbox
              isCurrency={false}
              required={setterData.referenceInfo[setterData.index].active}
              type={
                setterData.referenceInfo[setterData.index].referenceInfo.doNotCall
                  ? "password"
                  : "text"
              }
              inputlabel="Phone Number"
              OnChange={(e: any) =>
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    referenceInfo: {
                      ...setterData.referenceInfo[setterData.index].referenceInfo,
                      phoneNumber: e.target.value,
                    },
                  }
                )
              }
              maxlength={14}
              value={formatMobileNumber(value?.phoneNumber) || ""}
              disabled={
                Agrtransferinit || editEnabled
                  ? true
                  : !setterData.referenceInfo[setterData.index].active ||
                  setterData.referenceInfo[setterData.index].referenceInfo
                    .doNotCall
              }
              errorMessage={referenceErrorMessage[index].referenceInfo.phoneNumber}
            />
          </Grid>
          <Grid item md={3} lg={2}>
            <RACSelect
              inputLabel="Best Time to Call"
              options={bestTimeToCallOptions}
              defaultValue={value?.bestTimeToCall}
              isDisabled={
                Agrtransferinit || editEnabled
                  ? true
                  : !setterData.referenceInfo[setterData.index]
              }
              loading={bestTimeToCallOptions.length == 0 ? true : false}
              onChange={(e: any) =>
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    referenceInfo: {
                      ...setterData.referenceInfo[setterData.index].referenceInfo,
                      bestTimeToCall: e.target.value,
                    },
                  }
                )
              }
            />
          </Grid>
          <Grid item md={3} lg={2}>
            <RACSelect
              inputLabel="Relationship"
              options={configurations.customerVerificationOptimization ? verificationOptimizationOptions : relationshipOptions}
              isDisabled={Agrtransferinit || editEnabled}
              defaultValue={value?.relationShip}
              required={setterData.referenceInfo[setterData.index].active}
              loading={relationshipOptions.length == 0 ? true : false}
              onChange={(e: any) =>
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    referenceInfo: {
                      ...setterData.referenceInfo[setterData.index].referenceInfo,
                      relationShip: e.target.value,
                      relationshipTypeDesc: e.target.name
                    },
                  }
                )
              }
              errorMessage={referenceErrorMessage[index].referenceInfo.relationShip}
            />
          </Grid>
          <Grid item md={4} lg={2} className={classes.mt19pxRem}>
            <RACCheckBox
              label="Do Not Call"
              size="small"
              onChange={(e: any) =>
                updateReferenceInfo(
                  setterData.index,
                  setterData.referenceInfo,
                  setterData.setReferenceInfo,
                  {
                    referenceInfo: {
                      ...setterData.referenceInfo[setterData.index].referenceInfo,
                      doNotCall: e.target.checked,
                    },
                  }
                )
              }
              checked={value?.doNotCall}
              disabled={
                Agrtransferinit || editEnabled
                  ? true
                  : !setterData.referenceInfo[setterData.index].active
              }
            />
          </Grid>
        </Grid>
      }
    </Grid>
  );
}
