/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid, RACButton, RACSelect, RACTextbox, RACTooltip, Typography } from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { EmployersInfoProps } from "../../../interface/globalInterface";
import { handleFileChange, isDropDownValueExist, updateEmploymentDetails } from "../Utils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { DEFAULT_DROPDOWN_OPTION, MODULE_NAME } from "../../../constants/constants";
import { ReactComponent as BlueInfoIcon } from "./../../../../assets/images/blue-info-icon.svg";
import { ReactComponent as ViewIcon } from "./../../../../assets/images/View-Icon.svg";
import { DynamicAlertPopup } from "../DynamicAlertPopup";
import { DocumentViewer } from "../DocumentViewer";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";

export default function IncomeVerification({
  value,
  setterData,
}: EmployersInfoProps) {
  const classes = globalStyles();
  const { index, errorMessage } = setterData;
  const Agrtransferinit = false;
  const { payDayFrequencyOptions, weeklyDropdownOptions, monthlyDropdownOptions, semiMonthlyDropdownOptions, incomeDoc, setIncomeDoc, verificationDocuments, currentRole, configurations, incomeDocCopy, setincomeDocCopy, incomeSourceDropDownOptions } = useContext(UpdateCustomerContext);
  const fileInputRef = useRef<any>(null);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [errorMessge, setErrorMessage] = useState<string>('');
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<boolean>(false);
  const [tempIncomeDoc, setTempIncomeDoc] = useState<string>(incomeDoc);

  useEffect(() => {
    if (verificationDocuments) {
      console.log("VerificationDocuments", verificationDocuments);
      const incomeDocument: any = verificationDocuments?.filter(
        (doc: { refCode: any }) => doc.refCode === "EMPDOC"
      );
      // Find the most recent one
      const recentIncomeDocument: any = incomeDocument && incomeDocument?.length && incomeDocument?.reduce(
        (latest: any, current: any) => {
          return new Date(latest.createdDate) > new Date(current.createdDate)
            ? latest
            : current;
        }
      );
      console.log("recentIncomeDocument", recentIncomeDocument?.file);
      if (recentIncomeDocument?.file) {
        setIncomeDoc(recentIncomeDocument?.file);
        setShowDocument(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDocuments])
  useEffect(() => {
    if (incomeDoc) {
      incomeDoc == incomeDocCopy ? setincomeDocCopy(incomeDoc) : null
      setShowDocument(true)
    }
  }, [incomeDoc])

  // Function to trigger file input click event
  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFile = async (event: any) => {
    console.log('File details', event.target.files);
    if (event.target.files && event.target.files?.[0]?.size) {

      const fileSizeInBytes = event.target.files?.[0]?.size;
      let fileSize: any = fileSizeInBytes;
      let sizeUnit = "bytes";

      if (fileSizeInBytes >= 1024) {
        fileSize = fileSizeInBytes / 1024;
        sizeUnit = "KB";
      }

      if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        sizeUnit = "MB";
      }

      if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        sizeUnit = "GB";
      }

      // Round the file size to 2 decimal places
      fileSize = fileSize.toFixed(2);

      if (sizeUnit !== "KB") {
        if ((sizeUnit == "MB" && Number(fileSize) > 2) || sizeUnit == "GB") {
          setMessagePopup(true);
          setErrorMessage("Please upload a file smaller than 2MB.");
        } else {
          const base64Data: any = await handleFileChange(event);
          setShowDocument(true);
          console.log('File Base64Data', base64Data);
          let data: any = base64Data?.split(',');
          if (data?.[0]?.includes('application/pdf')) {
            data = data[1];
          } else {
            data = base64Data;
          }
          setIncomeDoc(data);
        }
      } else {
        const base64Data: any = await handleFileChange(event);
        setShowDocument(true);
        console.log('File Base64Data', base64Data);
        let data: any = base64Data?.split(',');
        if (data?.[0]?.includes('application/pdf')) {
          data = data[1];
        } else {
          data = base64Data;
        }
        setIncomeDoc(data)
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRemoveDocument = () => {
    setShowDocument(false);
  }


  return (
    <Grid container spacing={2} className={`${classes.mt10px}`}>
      <Grid item md={12}>
        <Typography
          className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
        >
          Income Verification
        </Typography>
      </Grid>
      {configurations?.EnableCustomerVerificationPhaseTwo && (<Grid
        item
        md={3}
        lg={2}
        className={`${classes.pt0} ${classes.inputFiledMargin}`}
      >
        <RACSelect
          inputLabel="Income Source"
          options={incomeSourceDropDownOptions}
          defaultValue={
            isDropDownValueExist(
              incomeSourceDropDownOptions,
              value.incomeVerification.incomeSource
            )
              ? value.incomeVerification.incomeSource
              : ""
          }
          loading={incomeSourceDropDownOptions.length == 0 ? true : false}
          isDisabled={
            Agrtransferinit || value.isEditEnabled ? true : !value.active
          }
          onChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                verifiedDate: "",
                incomeVerification: {
                  ...value.incomeVerification,
                  incomeSource: e.target.value,
                },
              }
            );
          }}
          errorMessage={
            errorMessage[index]?.incomeVerification?.paydayFrequency
          }
          inputLabelClassName={`${classes.inputFiledMargin}`}
        />
      </Grid>)}
      <Grid
        item
        md={3}
        lg={2}
        className={`${classes.pt0} ${classes.inputFiledMargin}`}
      >
        <RACSelect
          inputLabel="Payday Frequency"
          options={payDayFrequencyOptions}
          defaultValue={
            isDropDownValueExist(
              payDayFrequencyOptions,
              value.incomeVerification.paydayFrequency
            )
              ? value.incomeVerification.paydayFrequency
              : "0"
          }
          required={value.active}
          loading={payDayFrequencyOptions.length == 0 ? true : false}
          isDisabled={
            Agrtransferinit || value.isEditEnabled ? true : !value.active
          }
          onChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                verifiedDate: "",
                incomeVerification: {
                  ...value.incomeVerification,
                  paydayFrequency: e.target.value,
                  payDayFreqDesc: e.target.name,
                  dayPaid: "",
                },
              }
            );
          }}
          errorMessage={
            errorMessage[index]?.incomeVerification?.paydayFrequency
          }
        />
      </Grid>
      <Grid
        item
        md={2}
        lg={2}
        className={`${classes.pt0} ${classes.inputFiledMargin}`}
      >
        <RACSelect
          inputLabel="Day Paid"
          options={
            value.incomeVerification.paydayFrequency == "WK" ||
              value.incomeVerification.paydayFrequency == "BIWK"
              ? weeklyDropdownOptions
              : value.incomeVerification.paydayFrequency == "MON"
                ? monthlyDropdownOptions
                : value.incomeVerification.paydayFrequency == "SEMI"
                  ? semiMonthlyDropdownOptions
                  : [...DEFAULT_DROPDOWN_OPTION]
          }
          defaultValue={
            isDropDownValueExist(
              value.incomeVerification.paydayFrequency == "WK" ||
                value.incomeVerification.paydayFrequency == "BIWK"
                ? weeklyDropdownOptions
                : value.incomeVerification.paydayFrequency == "MON"
                  ? monthlyDropdownOptions
                  : value.incomeVerification.paydayFrequency == "SEMI"
                    ? semiMonthlyDropdownOptions
                    : [...DEFAULT_DROPDOWN_OPTION],
              value.incomeVerification.dayPaid
            )
              ? value.incomeVerification.dayPaid
              : "0"
          }
          required={
            value.active &&
              value.incomeVerification.paydayFrequency &&
              value.incomeVerification.paydayFrequency != "0" &&
              value.incomeVerification.paydayFrequency != "DAILY"
              ? true
              : false
          }
          isDisabled={
            Agrtransferinit || value.isEditEnabled
              ? true
              : !value.active ||
              value.incomeVerification.paydayFrequency == "DAILY" ||
              !value.incomeVerification.paydayFrequency ||
              value.incomeVerification.paydayFrequency == "0"
          }
          onChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                verifiedDate: "",
                incomeVerification: {
                  ...value.incomeVerification,
                  dayPaid: e.target.value,
                  daysPaidDesc: e.target.name,
                },
              }
            );
          }}
          errorMessage={errorMessage[index]?.incomeVerification?.dayPaid}
        />
      </Grid>
      <Grid
        item
        md={2}
        lg={2}
        className={`${classes.pt0} ${classes.inputFiledMargin1}`}
      >
        <RACTextbox
          type={"number"}
          isCurrency={true}
          digitFormat={"currency"}
          inputlabel="Take-Home Pay"
          maxlength={6}
          OnChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                incomeVerification: {
                  ...value.incomeVerification,
                  takeHomePay: e.target.value,
                },
              }
            );
          }}
          value={value.incomeVerification.takeHomePay}
          disabled={
            Agrtransferinit || value.isEditEnabled ? true : !value.active
          }
          className={`${classes.currentTextBoxStyle}`}
          dollarTextClassName={classes.customerDollerInputFiled}
        />
      </Grid>
      {configurations?.DocumentUploadEnable && (<Grid
        item
        md={3}
        lg={3}
        className={`${classes.pt0} ${classes.displayFlex} ${classes.flexDirectionColumn}`}
      >
        <Grid>
          <Typography component={"label"}>
            Documents{" "}
            <Typography component={"span"} style={{ color: "red" }}>
              *
            </Typography>
          </Typography>
          <RACTooltip
            placement={"top"}
            className={classes.tooltipcss}
            classes={{
              tooltip: classes.toolTipStyle4,
              arrow: classes.arrowStyle1,
            }}
            title={
              <Grid
                className={`${classes.textCenter} ${classes.displayFlex} ${classes.justifyContentCenter} ${classes.flexDirectionColumn} ${classes.width100p}`}
              >
                <Typography
                  component={`label`}
                  className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
                >
                  Acceptable Documents
                </Typography>
                <Typography
                  component={`label`}
                  className={`${classes.fontSize14px}`}
                >
                  Most recent income source document that can validate the
                  customer’s income
                </Typography>
                <Typography component={`label`}>
                  (e.g., Pay Stub, Bank Statement, Social Security Stub, etc)
                </Typography>
              </Grid>
            }
          >
            <BlueInfoIcon
              style={{
                height: "15px",
                marginLeft: "0px",
                marginTop: "6px",
              }}
            />
          </RACTooltip>
        </Grid>
        {showDocument ? (
          <Grid className={`${classes.displayFlex}`}>
            <Typography
              component={"label"}
              className={`${classes.fontPrimaryColor} ${classes.p10px} ${classes.fontOpensansBold} ${classes.font16}`}
            >
              Proof of Income.pdf
            </Typography>
            <Typography
              component={"label"}
              className={`${classes.fontPrimaryColor} ${classes.pointer} ${classes.p10px} ${classes.fontOpensansBold} ${classes.font16}`}
              onClick={() => {
                setShowDocument(false);
                setIncomeDoc("");
              }}
            >
              X
            </Typography>
            <Typography
              onClick={() => {
                setTwoFactorAuth(true);
              }}
              className={`${classes.buttonsColor1} ${classes.pointer} ${classes.displayFlex} ${classes.justifyContentSpaceAround} ${classes.font16} ${classes.fontOpensansBold}`}
            >
              View
              <ViewIcon
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: "2px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTwoFactorAuth(true);
                }}
              />
            </Typography>
          </Grid>
        ) : (
          <RACButton
            variant="contained"
            color="primary"
            id="uploadDocument"
            disabled={
              Agrtransferinit || value.isEditEnabled ? true : !value.active
            }
            onClick={() => {
              handleButtonClick();
            }}
            className={`${classes.buttonsColor} ${classes.pointer}`}
          >
            Upload
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFile}
              accept=".png,.jpeg,.jpg,.pdf"
              style={{ display: "none" }}
            />
          </RACButton>
        )}
      </Grid>)}
      {messagePopup ? (
        <DynamicAlertPopup
          alertType=""
          alertMessage={errorMessge}
          closeIcon={true}
          onCloseFn={() => {
            setMessagePopup(false);
          }}
        />
      ) : null}
      {openDocument ? (
        <DocumentViewer
          base64String={tempIncomeDoc}
          onCloseFn={() => {
            setOpenDocument(false);
          }}
          popupTitle="Proof of Income"
        />
      ) : null}

      {twoFactorAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setTwoFactorAuth(false);
          }}
          setTwoFactorCompleted={() => {
            setOpenDocument(true);
          }}
          moduleName={MODULE_NAME.EMPLOYMENT_DOCUMENT_ACCESS}
          currentRole={currentRole}
        />
      ) : null}
    </Grid>
  );
}