/* eslint-disable */
import { Grid, RACModalCard, Typography } from "@rentacenter/racstrap";
import { useEffect, useState } from "react";
import { cleanBase64String } from "./Utils";
import { globalStyles } from "../../jsStyles/globalStyles";

interface DocumentViewerProps {
  base64String: string;
  onCloseFn: () => any;
  popupTitle: string
}

export function DocumentViewer(props: DocumentViewerProps) {
    const classes = globalStyles();
  const { base64String, onCloseFn, popupTitle } = props;
  const [iframeData, setIframeData] = useState<string>("");

  useEffect(() => {

    const formatURL = async () => {
    let data: any = base64String?.split(',');
    if(data?.[0]?.includes('application/pdf')){
        data = data[1];
    } else {
        data = base64String;
    }

      const url = await b64toBlob(data, "application/pdf");
      const formatedURL: any = URL.createObjectURL(url);
      console.log("IframeUrl", formatedURL);
      setIframeData(formatedURL);
    };

    formatURL();
  }, []);

  const b64toBlob = async (
    b64Data: any,
    contentType = "application/pdf",
    sliceSize = 512
  ) => {
    console.log('b64Data', b64Data);
    // const base64Data: string = await cleanBase64String(b64Data);
    // console.log('Base 64 String', base64Data);
    const byteCharacters = atob(b64Data);
    const byteArrays: any = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const popupContent = () => (
    <Grid container style={{ height: '100%' }}>
      <Grid item md={12} style={{ textAlign: "center", height: '100%' }}>
        {iframeData ? (
          <Grid>
            <iframe
              src={`${iframeData}#toolbar=0&zoom=110`}
              width="100%"
              height="400"
            />
          </Grid>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Grid>
    </Grid>
  );
  return (
    <>
      <RACModalCard
        borderRadius="20px"
        isOpen={true}
        maxWidth="md"
        closeIcon={true}
        className={`${classes.customModal}`}
        title={popupTitle}
        onClose={() => {
          onCloseFn();
        }}
        children={popupContent()}
      />
    </>
  );
}
