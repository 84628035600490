/* eslint-disable */
import React, { useContext } from "react";
import { Grid, Typography } from "@rentacenter/racstrap";
import {
  ContactInfoHeaderProps,
  ReferenceDetailsProps
} from "../../../interface/globalInterface";
import ContactInfoHeader from "../ContactInfoHeader";
import ReferenceDetailsContainer from "./ReferenceDetailsContainer";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";

export default function ReferenceInformation(props: {
  value: ReferenceDetailsProps;
}) {
  const { referenceInfo, setReferenceInfo } = props.value;
  const { referenceErrorTitle, configurations } = useContext(UpdateCustomerContext);
  const classes = globalStyles();
  const index = 0;

  const infoContent = configurations?.EnableCustomerVerificationPhaseTwo ? (
    <Grid>
      <Typography component={'label'} className={`${classes.fontSize14px}`}>
        Reference Verification
      </Typography>
      <Typography>Provides additional contact(s) for collections purposes</Typography>
    </Grid>
  ) : 'Either 1 family member reference OR 2 non-family members'

  const contentHeading: ContactInfoHeaderProps = {
    heading: configurations?.EnableCustomerVerificationPhaseTwo ? 'Reference' : "Reference Information",
    popupHeading: referenceErrorTitle,
    popupFunctionality: null,
    params: null,
    infoIcon: true,
    infoIconContent: infoContent,
    tooltipClass: configurations?.EnableCustomerVerificationPhaseTwo ? classes.toolTipStyle6 : undefined,
    tooltipArrowClass: configurations?.EnableCustomerVerificationPhaseTwo ? classes.arrowStyle1 : undefined
  };

  return (
    <Grid container className={`${classes.pt05p}`}>
      <Grid container>
        <ContactInfoHeader value={contentHeading} />
        <ReferenceDetailsContainer
          setterData={{ index, referenceInfo, setReferenceInfo }}
        />
      </Grid>
    </Grid>
  );
}
